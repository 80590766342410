import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import themeList from "../configs/themeList";
import getUITheme from "../configs/getUITheme";
import { updateFontFamily } from "../redux/actions";
import fontList from "../configs/fontList";
import { useTheme } from "../hooks/useTheme";
import { State } from "../props/State.props";

const ThemeChanger = () => {
  const getFontFamily = useSelector((state: State) => state.getFontFamily);
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [, setTheme] = useTheme();
  const getTheme = useSelector((state: State) => state.getTheme);

  return (
    <div
      className={`fixed flex flex-col bg-gray-300 bottom-4 right-4 shadow-lg border-2 ${
        isCollapsed
          ? "w-12 h-12 rounded-3xl p-0 cursor-pointer"
          : "w-64 h-96 rounded-lg p-4"
      } transition-all ease-in-out duration-200`}
      onClick={() => isCollapsed && setIsCollapsed(!isCollapsed)}
    >
      {isCollapsed && (
        <div className="w-11 h-12 p-0 flex justify-center items-center">
          <i className="fa fa-brush" />
        </div>
      )}
      {!isCollapsed && (
        <>
          <div
            className="flex p-2 justify-end mb-4 cursor-pointer"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <i className="fa fa-times" />
          </div>
          <span className="text-lg font-bold mb-2">Select Theme</span>
          <div
            className={`bg-gray-200 rounded-lg ${
              !isCollapsed
                ? "w-auto py-2 px-5 flex justify-between items-center"
                : "p-3 flex justify-center items-center rounded-lg"
            }`}
          >
            {!isCollapsed &&
              themeList.map((item, index) => {
                const themeKey = index;
                return (
                  <div
                    key={themeKey}
                    className={`w-8 h-8 ${getUITheme(item).bg()} rounded-full ${
                      getTheme === item ? "border-2 border-black" : ""
                    } cursor-pointer`}
                    onClick={() => setTheme(item)}
                  />
                );
              })}
          </div>
          <span className="text-lg font-bold mt-8 mb-2">Select Font</span>
          <div className="flex items-center flex-wrap">
            {fontList.map((font, index) => {
              const fontKey = index;
              return (
                <div
                  key={fontKey}
                  className={`p-2 bg-gray-200 m-1 w-auto font-medium border-2 rounded-md ${
                    getFontFamily === font ? "border-slate-600" : "border-white"
                  } cursor-pointer`}
                  onClick={() => dispatch(updateFontFamily(font))}
                >
                  {font}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ThemeChanger;
