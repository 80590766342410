import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getUIFontFamily from "../configs/getUIFontFamily";
import FontsHookProps from "../props/FontsHook.props";
import { State } from "../props/State.props";
import { updateFontFamily } from "../redux/actions";

export const useFontFamily = (): FontsHookProps => {
  const dispatch = useDispatch();
  const getFontFamily = useSelector((state: State) => state.getFontFamily);
  const [fontFamily, setFontFamily] = useState(getFontFamily);

  useEffect(() => {
    dispatch(updateFontFamily(fontFamily));
  }, [dispatch, fontFamily]);

  return [getUIFontFamily(getFontFamily), setFontFamily];
};
