import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { State } from "../props/State.props";

function DownloadJSON() {
  const getResumeData = useSelector((state: State) => state.getResumeData);
  const navigate = useNavigate();

  return (
    <div
      className={`fixed cursor-pointer rounded-full flex flex-col bg-gray-300 bottom-20 right-4 shadow-lg border-2 transition-all ease-in-out duration-200`}
      title="Download JSON"
      onClick={() => {
        const getJSON =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(getResumeData));
        const anchorNode = document.createElement("a");
        anchorNode.setAttribute("href", getJSON);
        anchorNode.setAttribute("download", `${new Date().getTime()}.json`);
        anchorNode.click();
        anchorNode.remove();
        navigate("/downloaded");
      }}
    >
      <div className="w-11 h-12 flex justify-center items-center">
        <i className="fa fa-download" />
      </div>
    </div>
  );
}

export default DownloadJSON;
