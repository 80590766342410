import { BrowserRouter, Routes, Route } from "react-router-dom";
import Builder from "./screens/Builder";
import Download from "./screens/Download";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Builder />} />
        <Route path="/downloaded" element={<Download />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
