import { useState } from "react";
import { useTheme } from "../hooks/useTheme";
import { useFontFamily } from "../hooks/useFontFamily";
import { useResumeData } from "../hooks/useResumeData";
import { getData } from "../redux/reducer";
import { RSLayout as RSLayoutProps } from "../props/State.props";

function RSLayout() {
  const [theme] = useTheme();
  const [font] = useFontFamily();
  const [resumeData, setResumeData] = useResumeData();
  const [flagr, setFlagr] = useState("");

  const updateSkillsTitleToDef = (i: number) => {
    return `Skill ${i + 1}`;
  };

  const updateOrgTitleToDef = (i: number) => {
    return `Organization ${i + 1}`;
  };

  const renderSkillsLayout = (dataIndex: number, data: RSLayoutProps) => {
    if (data.type === "skill") {
      return (
        <div className="w-full pr-4" key={dataIndex}>
          {flagr === `r-ls-skills-${dataIndex}` ? (
            <div className={`flex mb-5 items-center`}>
              <input
                className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()} outline-none bg-transparent`}
                value={`${resumeData.rsLayout[dataIndex].title.toUpperCase()}`}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  resumeData.rsLayout[dataIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event) => {
                  if (event.target.value.trim() === "") {
                    resumeData.rsLayout[dataIndex].title =
                      getData.rsLayout[dataIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
                onFocus={(event) => {
                  event.stopPropagation();
                  event.target.style.width = `${
                    event.target.value.length > 6
                      ? event.target.value.length
                      : 5
                  }ch`;
                }}
              />
              {flagr === `r-ls-skills-${dataIndex}` && (
                <div
                  className={`ml-8 cursor-pointer ${theme.primary_fg()} text-xl`}
                  onClick={(event) => {
                    event.stopPropagation();
                    resumeData.rsLayout[dataIndex].record.skills!.push(
                      updateSkillsTitleToDef(
                        resumeData.rsLayout[dataIndex].record.skills!.length
                      )
                    );
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i className="fa fa-circle-plus" />
                </div>
              )}
            </div>
          ) : (
            <span
              className={`font-bold border-t-2 border-b-2 border-black text-xl mb-5 ${theme.primary_fg()}`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr(`r-ls-skills-${dataIndex}`);
              }}
            >
              {data.title.toUpperCase()}
            </span>
          )}
          <div className="flex flex-row mb-7 mt-5 flex-wrap">
            {data.record.skills!.map((skill, skillIndex) => {
              const recIndex = skillIndex;
              return flagr === `r-ls-skill-input-${skillIndex}` ? (
                <div
                  key={recIndex}
                  className={`relative flex rounded-md`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setFlagr(`r-ls-skill-input-${skillIndex}`);
                  }}
                >
                  <input
                    className={`py-1 px-3 mb-2 ${theme.bg()} mr-2 ${theme.primary_fg()} border rounded-md outline-none bg-transparent`}
                    value={`${
                      resumeData.rsLayout[dataIndex].record.skills![skillIndex]
                    }`}
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      resumeData.rsLayout[dataIndex].record.skills![
                        skillIndex
                      ] = event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event) => {
                      if (event.target.value.trim() === "") {
                        resumeData.rsLayout[dataIndex].record.skills![
                          skillIndex
                        ] = getData.rsLayout[dataIndex].record.skills![0];
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                      event.target.style.width = `${event.target.value.length}ch`;
                    }}
                  />
                  {flagr === `r-ls-skill-input-${skillIndex}` &&
                    skillIndex !== 0 && (
                      <div
                        className={`absolute flex ${theme.bg()} -mt-2 w-5 h-5 rounded-full -ml-2 items-center justify-center cursor-pointer`}
                        onClick={(event) => {
                          event.stopPropagation();
                          resumeData.rsLayout[dataIndex].record.skills!.splice(
                            skillIndex,
                            1
                          );
                          setResumeData({ ...resumeData });
                        }}
                      >
                        <i className={`fa fa-times ${theme.secondary_fg()}`} />
                      </div>
                    )}
                </div>
              ) : (
                <div
                  key={recIndex}
                  className={`relative flex py-1 px-3 mb-2 ${theme.bg()} mr-3 ${theme.secondary_fg()} rounded-md`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setFlagr(`r-ls-skill-input-${skillIndex}`);
                  }}
                >
                  <div>{skill}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  const renderOrgLayout = (dataIndex: number, data: RSLayoutProps) => {
    if (data.type === "org") {
      return (
        <div className="w-full pr-4" key={dataIndex}>
          {flagr === `r-ls-org-${dataIndex}` ? (
            <div
              className={`flex mb-5 text-xl items-center ${theme.primary_fg()}`}
            >
              <input
                className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()} outline-none bg-transparent`}
                value={`${resumeData.rsLayout[dataIndex].title.toUpperCase()}`}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  resumeData.rsLayout[dataIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event) => {
                  if (event.target.value.trim() === "") {
                    resumeData.rsLayout[dataIndex].title =
                      getData.rsLayout[dataIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
                onFocus={(event) => {
                  event.stopPropagation();
                  event.target.style.width = `${
                    event.target.value.length > 13
                      ? event.target.value.length
                      : 13
                  }ch`;
                }}
              />
              {flagr === `r-ls-org-${dataIndex}` && (
                <div
                  className="ml-8 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    resumeData.rsLayout[dataIndex].record.organizations!.push(
                      updateOrgTitleToDef(
                        resumeData.rsLayout[dataIndex].record.organizations!
                          .length
                      )
                    );
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i className="fa fa-circle-plus" />
                </div>
              )}
            </div>
          ) : (
            <div
              className={`flex mb-5 text-xl items-center ${theme.primary_fg()}`}
            >
              <span
                className={`font-bold border-t-2 border-b-2 border-black`}
                onClick={(event) => {
                  event.stopPropagation();
                  setFlagr(`r-ls-org-${dataIndex}`);
                }}
              >
                {data.title.toUpperCase()}
              </span>
            </div>
          )}
          {data.record.organizations!.map((org, orgIndex) => {
            const recIndex = orgIndex;
            return (
              <div className="mb-4 flex-wrap" key={recIndex}>
                {flagr === `r-ls-org-input-${orgIndex}` ? (
                  <div className="flex">
                    <input
                      className={`flex py-1 mr-4 text-sm font-medium outline-none bg-transparent`}
                      value={`${
                        resumeData.rsLayout[dataIndex].record.organizations![
                          orgIndex
                        ]
                      }`}
                      autoFocus
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        resumeData.rsLayout[dataIndex].record.organizations![
                          orgIndex
                        ] = event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event) => {
                        if (event.target.value.trim() === "") {
                          resumeData.rsLayout[dataIndex].record.organizations![
                            orgIndex
                          ] =
                            getData.rsLayout[
                              dataIndex
                            ].record.organizations![0];
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                      onFocus={(event) => {
                        event.stopPropagation();
                        event.target.style.width = `${event.target.value.length}ch`;
                      }}
                    />
                    {flagr === `r-ls-org-input-${orgIndex}` && orgIndex !== 0 && (
                      <div
                        className={`flex rounded-full ${
                          orgIndex === 0 ? "ml-10" : "ml-11"
                        } items-center justify-center cursor-pointer`}
                        onClick={(event) => {
                          event.stopPropagation();
                          resumeData.rsLayout[
                            dataIndex
                          ].record.organizations!.splice(orgIndex, 1);
                          setResumeData({ ...resumeData });
                        }}
                      >
                        <i className={`fa fa-trash ${theme.primary_fg()}`} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    key={recIndex}
                    className={`flex py-1 mb-2 mr-4 text-sm font-medium`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-org-input-${orgIndex}`);
                    }}
                  >
                    <div>{org}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={`w-full md:w-6/12 xs:pl-0 xs:mt-10 md:pl-10 md:mt-0 ${font}`}
      onClick={() => {
        setFlagr("");
      }}
    >
      {resumeData.rsLayout.map((data, dataIndex) => {
        return (
          <>
            {renderSkillsLayout(dataIndex, data)}
            {renderOrgLayout(dataIndex, data)}
          </>
        );
      })}
    </div>
  );
}

export default RSLayout;
