import { useState } from "react";
import { useTheme } from "../hooks/useTheme";
import { useFontFamily } from "../hooks/useFontFamily";
import { useResumeData } from "../hooks/useResumeData";
import { getData } from "../redux/reducer";
import { LSLayout as LSLayoutProps } from "../props/State.props";

function LSLayout() {
  const [theme] = useTheme();
  const [font] = useFontFamily();
  const [resumeData, setResumeData] = useResumeData();
  const [flagr, setFlagr] = useState("");

  const updateExpTitleToDef = (i: number) => {
    return {
      role: `Position ${resumeData.lsLayout[i].record.length + 1}`,
      company: `Company ${resumeData.lsLayout[i].record.length + 1}`,
      location: "City, Country",
      date: "MM/YYYY-MM/YYYY",
      responsibilities: [
        "Develop high-quality software design and architecture",
        "Identify, prioritize and execute tasks in the software development life cycle",
        "Develop tools and applications by producing clean, efficient code",
      ],
    };
  };

  const updateEdTitleToDef = (i: number) => {
    return {
      degree: `Degree ${resumeData.lsLayout[i].record.length + 1}`,
      university: `University ${resumeData.lsLayout[i].record.length + 1}`,
      date: "MM/YYYY-MM/YYYY",
    };
  };

  const updateLangTitleToDef = (i: number) => {
    return {
      lang: `Langauge ${resumeData.lsLayout[i].record.length + 1}`,
      rate: 1,
    };
  };

  const renderExpLayout = (dataIndex: number, data: LSLayoutProps) => {
    if (data.type === "exp") {
      return (
        <div className="w-full pr-4" key={dataIndex}>
          {flagr === `r-ls-exp-${dataIndex}` ? (
            <div className={`flex text-xl items-center ${theme.primary_fg()}`}>
              <input
                className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()} outline-none bg-transparent`}
                value={`${resumeData.lsLayout[dataIndex].title.toUpperCase()}`}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  resumeData.lsLayout[dataIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event) => {
                  if (event.target.value.trim() === "") {
                    console.log(getData);
                    resumeData.lsLayout[dataIndex].title =
                      getData.lsLayout[dataIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
                onFocus={(event) => {
                  event.stopPropagation();
                  event.target.style.width = `${
                    event.target.value.length > 9
                      ? event.target.value.length
                      : 9 + 0.5
                  }ch`;
                }}
              />
              {flagr === `r-ls-exp-${dataIndex}` && (
                <div
                  className="ml-8 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    resumeData.lsLayout[dataIndex].record.push(
                      updateExpTitleToDef(dataIndex)
                    );
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i className="fa fa-circle-plus" />
                </div>
              )}
            </div>
          ) : (
            <div className={`flex text-xl items-center ${theme.primary_fg()}`}>
              <span
                className={`font-bold border-t-2 border-b-2 border-black`}
                onClick={(event) => {
                  event.stopPropagation();
                  setFlagr(`r-ls-exp-${dataIndex}`);
                }}
              >
                {data.title.toUpperCase()}
              </span>
            </div>
          )}
          {data.record.map((rec, recIndex) => {
            const dataKey = recIndex;
            return (
              <div className="mb-5" key={dataKey}>
                {flagr === `r-ls-role-${recIndex}` ? (
                  <div className={`flex items-center`}>
                    <input
                      className={`${
                        recIndex === 0 ? "mt-4" : ""
                      } text-md font-bold outline-none bg-transparent`}
                      value={`${resumeData.lsLayout[dataIndex].record[recIndex].role}`}
                      autoFocus
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        resumeData.lsLayout[dataIndex].record[recIndex].role =
                          event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event) => {
                        if (event.target.value.trim() === "") {
                          resumeData.lsLayout[dataIndex].record[recIndex].role =
                            getData.lsLayout[dataIndex].record[0].role;
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                    {flagr === `r-ls-role-${recIndex}` && recIndex !== 0 && (
                      <div
                        className="ml-8 cursor-pointer"
                        onClick={(event) => {
                          event.stopPropagation();
                          resumeData.lsLayout[dataIndex].record.splice(
                            recIndex,
                            1
                          );
                          setResumeData({ ...resumeData });
                        }}
                      >
                        <i
                          className={`fa fa-trash text-sm ${
                            recIndex === 0 ? "mt-4" : ""
                          }`}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    key={dataKey}
                    className={`${
                      recIndex === 0 ? "mt-4" : ""
                    } text-md font-bold`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-role-${recIndex}`);
                    }}
                  >
                    {rec.role}
                  </div>
                )}
                {flagr === `r-ls-company-${recIndex}` ? (
                  <input
                    className={`text-sm font-medium outline-none bg-transparent`}
                    value={`${resumeData.lsLayout[dataIndex].record[recIndex].company}`}
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      resumeData.lsLayout[dataIndex].record[recIndex].company =
                        event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event) => {
                      if (event.target.value.trim() === "") {
                        resumeData.lsLayout[dataIndex].record[
                          recIndex
                        ].company =
                          getData.lsLayout[dataIndex].record[0].company;
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                ) : (
                  <div
                    className="text-sm font-medium"
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-company-${recIndex}`);
                    }}
                  >
                    {rec.company}
                  </div>
                )}
                <div className="flex justify-between flex-wrap mt-2 w-full">
                  {flagr === `r-ls-date-${recIndex}` ? (
                    <input
                      className={`text-xs text-neutral-500 italic outline-none bg-transparent`}
                      value={`${resumeData.lsLayout[dataIndex].record[recIndex].date}`}
                      autoFocus
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        resumeData.lsLayout[dataIndex].record[recIndex].date =
                          event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event) => {
                        if (event.target.value.trim() === "") {
                          resumeData.lsLayout[dataIndex].record[recIndex].date =
                            getData.lsLayout[dataIndex].record[0].date;
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="text-xs text-neutral-500"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFlagr(`r-ls-date-${recIndex}`);
                      }}
                    >
                      <i>{rec.date}</i>
                    </div>
                  )}
                  {flagr === `r-ls-location-${recIndex}` ? (
                    <input
                      className={`text-xs text-neutral-500 italic outline-none bg-transparent text-right`}
                      value={`${resumeData.lsLayout[dataIndex].record[recIndex].location}`}
                      autoFocus
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        resumeData.lsLayout[dataIndex].record[
                          recIndex
                        ].location = event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event) => {
                        if (event.target.value.trim() === "") {
                          resumeData.lsLayout[dataIndex].record[
                            recIndex
                          ].location =
                            getData.lsLayout[dataIndex].record[0].location;
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="text-xs text-neutral-500"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFlagr(`r-ls-location-${recIndex}`);
                      }}
                    >
                      <i>{rec.location}</i>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  {rec.responsibilities!.map((responsibility, resIndex) => {
                    const recKey = resIndex;
                    return (
                      <div className="flex items-start mb-1" key={recKey}>
                        <div className={`${theme.primary_fg()}`}>-</div>
                        {flagr ===
                        `r-ls-responsibilities-${recIndex}-${resIndex}` ? (
                          <div className={`flex items-center w-full`}>
                            <input
                              className={`ml-2 text-sm mt-1 outline-none bg-transparent w-full`}
                              value={`${
                                resumeData.lsLayout[dataIndex].record[recIndex]
                                  .responsibilities![resIndex]
                              }`}
                              autoFocus
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                resumeData.lsLayout[dataIndex].record[
                                  recIndex
                                ].responsibilities![resIndex] =
                                  event.target.value;
                                setResumeData({
                                  ...resumeData,
                                });
                              }}
                              onBlur={(event) => {
                                if (event.target.value.trim() === "") {
                                  resumeData.lsLayout[dataIndex].record[
                                    recIndex
                                  ].responsibilities![resIndex] =
                                    getData.lsLayout[dataIndex].record[
                                      recIndex
                                    ].responsibilities![0];
                                  setResumeData({
                                    ...resumeData,
                                  });
                                }
                              }}
                            />
                            {flagr ===
                              `r-ls-responsibilities-${recIndex}-${resIndex}` && (
                              <div className="ml-8 cursor-pointer flex items-center">
                                <i
                                  className="fa fa-circle-plus"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    resumeData.lsLayout[dataIndex].record[
                                      recIndex
                                    ].responsibilities!.splice(
                                      resIndex,
                                      0,
                                      getData.lsLayout[dataIndex].record[
                                        recIndex
                                      ].responsibilities![0]
                                    );
                                    setResumeData({ ...resumeData });
                                  }}
                                />
                                {resIndex !== 0 && (
                                  <i
                                    className="fa fa-trash ml-4"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      resumeData.lsLayout[dataIndex].record[
                                        recIndex
                                      ].responsibilities!.splice(resIndex, 1);
                                      setResumeData({ ...resumeData });
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="ml-2 text-sm mt-1"
                            onClick={(event) => {
                              event.stopPropagation();
                              setFlagr(
                                `r-ls-responsibilities-${recIndex}-${resIndex}`
                              );
                            }}
                          >
                            <div>{responsibility}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderEdLayout = (dataIndex: number, data: LSLayoutProps) => {
    if (data.type === "ed") {
      return (
        <div className="w-full pr-4 mt-10" key={dataIndex}>
          {flagr === `r-ls-ed-${dataIndex}` ? (
            <div className={`flex text-xl items-center ${theme.primary_fg()}`}>
              <input
                className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()} outline-none bg-transparent`}
                value={`${resumeData.lsLayout[dataIndex].title.toUpperCase()}`}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  resumeData.lsLayout[dataIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event) => {
                  if (event.target.value.trim() === "") {
                    resumeData.lsLayout[dataIndex].title =
                      getData.lsLayout[dataIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
                onFocus={(event) => {
                  event.stopPropagation();
                  event.target.style.width = `${
                    event.target.value.length > 9
                      ? event.target.value.length
                      : 9 + 0.5
                  }ch`;
                }}
              />
              {flagr === `r-ls-ed-${dataIndex}` && (
                <div
                  className="ml-8 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setFlagr(`r-ls-ed-${dataIndex}`);
                    resumeData.lsLayout[dataIndex].record.push(
                      updateEdTitleToDef(dataIndex)
                    );
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i className="fa fa-circle-plus" />
                </div>
              )}
            </div>
          ) : (
            <span
              className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()}`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr(`r-ls-ed-${dataIndex}`);
              }}
            >
              {data.title.toUpperCase()}
            </span>
          )}
          {data.record.map((rec, degIndex) => {
            const degKey = degIndex;
            return (
              <div className="mb-5" key={degKey}>
                {flagr === `r-ls-degree-${degIndex}` ? (
                  <div className={`flex items-center`}>
                    <input
                      className={`${
                        degIndex === 0 && "mt-4"
                      } text-md font-bold outline-none bg-transparent`}
                      value={`${resumeData.lsLayout[dataIndex].record[degIndex].degree}`}
                      autoFocus
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        resumeData.lsLayout[dataIndex].record[degIndex].degree =
                          event.target.value;
                        setResumeData({
                          ...resumeData,
                        });
                      }}
                      onBlur={(event) => {
                        if (event.target.value.trim() === "") {
                          resumeData.lsLayout[dataIndex].record[
                            degIndex
                          ].degree =
                            getData.lsLayout[dataIndex].record[0].degree;
                          setResumeData({
                            ...resumeData,
                          });
                        }
                      }}
                    />
                    {flagr === `r-ls-degree-${degIndex}` && degIndex !== 0 && (
                      <div
                        className="ml-8 cursor-pointer"
                        onClick={(event) => {
                          event.stopPropagation();
                          resumeData.lsLayout[dataIndex].record.splice(
                            degIndex,
                            1
                          );
                          setResumeData({ ...resumeData });
                        }}
                      >
                        <i className={`fa fa-trash text-sm`} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-md font-bold ${
                      degIndex === 0 ? "mt-4" : ""
                    }`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-degree-${degIndex}`);
                    }}
                  >
                    {rec.degree}
                  </div>
                )}
                {flagr === `r-ls-university-${degIndex}` ? (
                  <input
                    className={`
              text-sm font-medium outline-none bg-transparent`}
                    value={`${resumeData.lsLayout[dataIndex].record[degIndex].university}`}
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      resumeData.lsLayout[dataIndex].record[
                        degIndex
                      ].university = event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event) => {
                      if (event.target.value.trim() === "") {
                        resumeData.lsLayout[dataIndex].record[
                          degIndex
                        ].university =
                          getData.lsLayout[dataIndex].record[0].university;
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                ) : (
                  <div
                    className="text-sm font-medium"
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-university-${degIndex}`);
                    }}
                  >
                    {rec.university}
                  </div>
                )}
                {flagr === `r-ls-ed-date-${degIndex}` ? (
                  <input
                    className={`
            text-xs text-neutral-500 italic outline-none bg-transparent pt-1`}
                    value={`${resumeData.lsLayout[dataIndex].record[degIndex].date}`}
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      resumeData.lsLayout[dataIndex].record[degIndex].date =
                        event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event) => {
                      if (event.target.value.trim() === "") {
                        resumeData.lsLayout[dataIndex].record[degIndex].date =
                          getData.lsLayout[dataIndex].record[0].date;
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                ) : (
                  <div
                    className="flex justify-between flex-wrap mt-2"
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-ed-date-${degIndex}`);
                    }}
                  >
                    <div className="text-xs text-neutral-500">
                      <i>{rec.date}</i>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderLangLayout = (dataIndex: number, data: LSLayoutProps) => {
    if (data.type === "lang") {
      return (
        <div className="w-full pr-4 mt-10" key={dataIndex}>
          {flagr === `r-ls-lang-title-${dataIndex}` ? (
            <div className={`flex text-xl items-center ${theme.primary_fg()}`}>
              <input
                className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()} outline-none bg-transparent`}
                value={`${resumeData.lsLayout[dataIndex].title.toUpperCase()}`}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  resumeData.lsLayout[dataIndex].title = event.target.value;
                  setResumeData({
                    ...resumeData,
                  });
                }}
                onBlur={(event) => {
                  if (event.target.value.trim() === "") {
                    resumeData.lsLayout[dataIndex].title =
                      getData.lsLayout[dataIndex].title;
                    setResumeData({
                      ...resumeData,
                    });
                  }
                }}
                onFocus={(event) => {
                  event.stopPropagation();
                  event.target.style.width = `${
                    event.target.value.length > 9
                      ? event.target.value.length
                      : 9 + 0.5
                  }ch`;
                }}
              />
              {flagr === `r-ls-lang-title-${dataIndex}` && (
                <div
                  className="ml-8 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    resumeData.lsLayout[dataIndex].record.push(
                      updateLangTitleToDef(dataIndex)
                    );
                    setResumeData({ ...resumeData });
                  }}
                >
                  <i className="fa fa-circle-plus" />
                </div>
              )}
            </div>
          ) : (
            <span
              className={`text-xl font-bold border-t-2 border-b-2 border-black ${theme.primary_fg()}`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr(`r-ls-lang-title-${dataIndex}`);
              }}
            >
              {data.title.toUpperCase()}
            </span>
          )}
          {data.record.map((rec, langIndex) => {
            const langKey = langIndex;
            return (
              <div
                className="mb-5 flex justify-start flex-wrap mt-4"
                key={langKey}
              >
                {flagr === `r-ls-lang-${langIndex}` ? (
                  <input
                    className={`text-sm font-normal outline-none bg-transparent`}
                    style={{
                      maxWidth: "100px",
                    }}
                    value={`${resumeData.lsLayout[dataIndex].record[langIndex].lang}`}
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      resumeData.lsLayout[dataIndex].record[langIndex].lang =
                        event.target.value;
                      setResumeData({
                        ...resumeData,
                      });
                    }}
                    onBlur={(event) => {
                      if (event.target.value.trim() === "") {
                        resumeData.lsLayout[dataIndex].record[langIndex].lang =
                          getData.lsLayout[dataIndex].record[0].lang;
                        setResumeData({
                          ...resumeData,
                        });
                      }
                    }}
                  />
                ) : (
                  <div
                    className="text-sm font-normal"
                    style={{
                      minWidth: "100px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setFlagr(`r-ls-lang-${langIndex}`);
                    }}
                  >
                    {rec.lang}
                  </div>
                )}
                <div className="flex mt-2 items-center h-2">
                  <div className="md:ml-10 text-sm flex items-center">
                    {Array(5)
                      .fill(0)
                      .map((_num, i) => {
                        const numIndex = i;
                        return (
                          <div
                            key={numIndex}
                            className={`${
                              numIndex + 1 <= rec.rate!
                                ? theme.bg()
                                : "bg-transparent"
                            } w-3 h-3 rounded-full mx-2 border-2 ${theme.border()} cursor-pointer`}
                            onClick={(event) => {
                              event.stopPropagation();
                              resumeData.lsLayout[dataIndex].record[
                                langIndex
                              ].rate = numIndex + 1;
                              setResumeData({ ...resumeData });
                            }}
                          />
                        );
                      })}
                  </div>
                  {flagr === `r-ls-lang-${langIndex}` && langIndex !== 0 && (
                    <div
                      className="ml-8 cursor-pointer"
                      onClick={(event) => {
                        event.stopPropagation();
                        resumeData.lsLayout[dataIndex].record.splice(
                          langIndex,
                          1
                        );
                        setResumeData({ ...resumeData });
                      }}
                    >
                      <i className={`fa fa-trash text-sm`} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={`w-full md:w-6/12 ${font}`}
      onClick={() => {
        setFlagr("");
      }}
    >
      {resumeData.lsLayout.map((data, dataIndex) => {
        return (
          <>
            {renderExpLayout(dataIndex, data)}
            {renderEdLayout(dataIndex, data)}
            {renderLangLayout(dataIndex, data)}
          </>
        );
      })}
    </div>
  );
}

export default LSLayout;
