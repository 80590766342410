import { useNavigate } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import { useFontFamily } from "../hooks/useFontFamily";

function Download() {
  const navigate = useNavigate();
  const [theme] = useTheme();
  const [font] = useFontFamily();

  return (
    <div
      className={`${font} w-screen bg-slate-300 h-screen flex flex-col justify-center items-center`}
    >
      <div className="text-3xl font-extralight">File Downloaded</div>
      <div
        className={`mt-8 ${theme.bg()} ${theme.secondary_fg()} px-4 py-2 rounded-md cursor-pointer`}
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back
      </div>
    </div>
  );
}

export default Download;
