import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResumeDataHookProps from "../props/ResumeDataHook.props";
import { State } from "../props/State.props";
import { updateResumeData } from "../redux/actions";

export const useResumeData = (): ResumeDataHookProps => {
  const dispatch = useDispatch();
  const getResumeData = useSelector((state: State) => state.getResumeData);
  const [resumeData, setResumeData] = useState(getResumeData);

  useEffect(() => {
    setResumeData({ ...resumeData });
    dispatch(updateResumeData(resumeData));
  }, [dispatch, resumeData]);

  return [resumeData, setResumeData];
};
