import FontProps from "../props/Fonts.props";

const fontList: string[] = [
  "Montserrat",
  "Poppins",
  "Rubik",
  "Manrope",
  "Roboto",
  "Inter",
].sort();

export const fontTailwind: FontProps = {
  Montserrat: "font-Montserrat",
  Poppins: "font-Poppins",
  Rubik: "font-Rubik",
  Manrope: "font-Manrope",
  Roboto: "font-Roboto",
  Inter: "font-Inter",
};

export default fontList;
