import ThemeChanger from "../components/ThemeChanger";
import Intro from "../components/Intro";
import LSLayout from "../components/LSLayout";
import RSLayout from "../components/RSLayout";
import DownloadJSON from "../components/DownloadJSON";

function App() {
  return (
    <>
      <h2 className="mt-8 text-3xl text-center font-bold">Resume Builder</h2>
      <div className="p-8 mx-10 my-14 xl:mx-56 border-2 border-gray-300">
        <DownloadJSON />
        <ThemeChanger />
        <Intro />
        <div className="flex justify-between flex-wrap w-full mt-10">
          <LSLayout />
          <RSLayout />
        </div>
      </div>
    </>
  );
}

export default App;
