import { State } from "../props/State.props";

export const THEME = "THEME";
export const FONT_FAMILY = "FONT_FAMILY";
export const RESUME_DATA = "RESUME_DATA";

export function updateTheme(theme: string): {
  type: string;
  payload: string;
} {
  return {
    type: THEME,
    payload: theme,
  };
}

export function updateFontFamily(fontFamily: string): {
  type: string;
  payload: string;
} {
  return {
    type: FONT_FAMILY,
    payload: fontFamily,
  };
}

export function updateResumeData(resumeData: State["getResumeData"]): {
  type: string;
  payload: State["getResumeData"];
} {
  return {
    type: RESUME_DATA,
    payload: resumeData,
  };
}
