import { useState } from "react";
import { useTheme } from "../hooks/useTheme";
import { useFontFamily } from "../hooks/useFontFamily";
import { useResumeData } from "../hooks/useResumeData";
import { getData } from "../redux/reducer";

const Intro = () => {
  const [theme] = useTheme();
  const [resumeData, setResumeData] = useResumeData();
  const [font] = useFontFamily();
  const [flagr, setFlagr] = useState("");

  return (
    <div className={`flex justify-between flex-wrap w-full ${font}`}>
      <div className={"w-full md:w-5/12"}>
        {flagr === "r-user-name" ? (
          <input
            className={`${theme.primary_fg()}  text-2xl font-bold h-8 outline-none bg-transparent w-full`}
            value={`${resumeData.name}`}
            autoFocus
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setResumeData({ ...resumeData, name: event.target.value });
            }}
            onBlur={(event) => {
              if (event.target.value === "") {
                setResumeData({
                  ...resumeData,
                  name: getData.name,
                });
              }
              setFlagr("");
            }}
          />
        ) : (
          <div
            id="r-name"
            className={`${theme.primary_fg()}  text-2xl font-bold h-8`}
            onClick={(event) => {
              event.stopPropagation();
              setFlagr("r-user-name");
            }}
          >
            {`${resumeData.name}`}
          </div>
        )}
        {flagr === "r-user-position" ? (
          <input
            className={` text-gray-500 text-md h-6 outline-none bg-transparent w-full`}
            value={`${resumeData.position}`}
            autoFocus
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setResumeData({ ...resumeData, position: event.target.value });
            }}
            onBlur={(event) => {
              if (event.target.value === "") {
                setResumeData({
                  ...resumeData,
                  position: getData.position,
                });
              }
              setFlagr("");
            }}
          />
        ) : (
          <div
            id="r-user-name"
            className={` text-gray-500 text-md h-6 truncate`}
            onClick={(event) => {
              event.stopPropagation();
              setFlagr("r-user-position");
            }}
          >
            {`${resumeData.position}`}
          </div>
        )}
        {flagr === "r-user-description" ? (
          <textarea
            autoFocus
            spellCheck={false}
            rows={4}
            className={` resize-none w-full text-black text-sm mt-4 outline-none bg-transparent`}
            value={`${resumeData.description}`}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setResumeData({
                ...resumeData,
                description: event.target.value,
              });
            }}
            onFocus={(event) => {
              event.target.selectionStart = event.target.value.length;
              event.target.scrollTop = event.target.scrollHeight;
            }}
            onBlur={(event) => {
              if (event.target.value === "") {
                setResumeData({
                  ...resumeData,
                  description: getData.description,
                });
              }
              setFlagr("");
            }}
          />
        ) : (
          <div
            id="r-user-description"
            className={` text-black text-sm mt-4 line-clamp-4 text-ellipsis`}
            onClick={(event) => {
              event.stopPropagation();
              setFlagr("r-user-description");
            }}
            title={`${resumeData.description}`}
          >
            {`${resumeData.description}`}
          </div>
        )}
      </div>
      <div
        className={
          "w-12/12 md:w-6/12 md:pl-2 mt-6 md:mt-0 flex flex-col items-end"
        }
      >
        <div className="flex justify-center items-center w-full flex-row-reverse md:flex-row">
          {flagr === "r-user-email" ? (
            <input
              className={` text-black text-sm outline-none bg-transparent w-full text-left md:text-right font-medium`}
              value={`${resumeData.email}`}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setResumeData({ ...resumeData, email: event.target.value });
              }}
              onBlur={(event) => {
                if (event.target.value === "") {
                  setResumeData({
                    ...resumeData,
                    email: getData.email,
                  });
                }
                setFlagr("");
              }}
            />
          ) : (
            <div
              id="r-user-email"
              className={` text-black text-sm truncate w-full text-left md:text-right font-medium ml-2 md:ml-0`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr("r-user-email");
              }}
            >
              {`${resumeData.email}`}
            </div>
          )}
          <i
            className={`fa fa-envelope text-sm md:ml-2 ${theme.primary_fg()}`}
          />
        </div>
        <div className="flex justify-center items-center w-full flex-row-reverse md:flex-row mt-4">
          {flagr === "r-user-ph_no" ? (
            <input
              className={` text-black text-sm outline-none bg-transparent md:w-full text-left md:text-right font-medium`}
              value={`${resumeData.ph_no}`}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setResumeData({ ...resumeData, ph_no: event.target.value });
              }}
              onBlur={(event) => {
                if (event.target.value === "") {
                  setResumeData({
                    ...resumeData,
                    ph_no: getData.ph_no,
                  });
                }
                setFlagr("");
              }}
            />
          ) : (
            <div
              id="r-user-ph_no"
              className={` text-black text-sm truncate w-full text-left md:text-right font-medium ml-2 md:ml-0`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr("r-user-ph_no");
              }}
            >
              {`${resumeData.ph_no}`}
            </div>
          )}
          <i
            className={`fa-solid fa-mobile-screen text-md md:ml-2 ${theme.primary_fg()}`}
          />
        </div>
        <div className="flex justify-center items-center w-full flex-row-reverse md:flex-row mt-4">
          {flagr === "r-user-location" ? (
            <input
              className={` text-black text-sm outline-none bg-transparent w-full text-left md:text-right font-medium`}
              value={`${resumeData.location}`}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setResumeData({
                  ...resumeData,
                  location: event.target.value,
                });
              }}
              onBlur={(event) => {
                if (event.target.value === "") {
                  setResumeData({
                    ...resumeData,
                    location: getData.location,
                  });
                }
                setFlagr("");
              }}
            />
          ) : (
            <div
              id="r-user-location"
              className={` text-black text-sm truncate w-full text-left md:text-right font-medium ml-2 md:ml-0`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr("r-user-location");
              }}
            >
              {`${resumeData.location}`}
            </div>
          )}
          <i
            className={`fa-solid fa-location-dot text-md md:ml-2 ${theme.primary_fg()}`}
          />
        </div>
        <div className="flex justify-center items-center w-full flex-row-reverse md:flex-row mt-4">
          {flagr === "r-user-linkedin" ? (
            <input
              className={` text-black text-sm outline-none bg-transparent w-full text-left md:text-right font-medium`}
              value={`${resumeData.linkedin}`}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setResumeData({
                  ...resumeData,
                  linkedin: event.target.value,
                });
              }}
              onBlur={(event) => {
                if (event.target.value === "") {
                  setResumeData({
                    ...resumeData,
                    linkedin: getData.linkedin,
                  });
                }
                setFlagr("");
              }}
            />
          ) : (
            <div
              id="r-user-linkedin"
              className={` text-black text-sm truncate w-full text-left md:text-right font-medium ml-2 md:ml-0`}
              onClick={(event) => {
                event.stopPropagation();
                setFlagr("r-user-linkedin");
              }}
              title={`${resumeData.linkedin}`}
            >
              {`${resumeData.linkedin}`}
            </div>
          )}
          <i
            className={`fa-brands fa-linkedin text-md md:ml-2 ${theme.primary_fg()}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Intro;
